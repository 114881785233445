import { defineStore } from 'pinia'

export const useBrandsStore = defineStore('brandsStore', () => {
  const all = ref([])

  const brandBySlug = slug => all.value.find(c => c.slug === slug)
  const brandByUid = uid => all.value.find(c => c.uid === uid)
  const setAllBrands = brands => {
    all.value = brands
  }
  const addBrands = brands => {
    let allBrands = all.value.concat(brands)
    allBrands = allBrands.filter((b, index, self) => index === self.findIndex(brand => b?.uid === brand?.uid))
    all.value = allBrands
  }
  const getAll = async () => {
    const { $api } = useNuxtApp()
    try {
      await $api()
        .brand.getBrands()
        .then(rawBrands => {
          setAllBrands(rawBrands)
        })
    } catch (e) {
      console.log(e)
      throw createError({ statusCode: 404, message: e.message })
    }
  }
  return {
    all,
    brandBySlug,
    brandByUid,
    setAllBrands,
    addBrands,
    getAll,
  }
})
